@import "../../css/fonts";
@import "../../css/responsive";

.previewCardSlim {
    display: flex;
    width: 100%;
    height: 150px;
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    background-color: var(--dust-100);

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        min-width: 0;
        white-space: normal;

        .body {
            @include font-rubik(0.75rem, inherit, 400);
            margin: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            word-wrap: break-word;
        }

        .button {
            @include font-rubik(0.75rem, inherit, 600);
            border: none;
            padding: 0.25rem 1rem;
            border-radius: 2rem;
            font-weight: 600;
        }

        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: auto;

            .sponsored {
                @include font-rubik(0.75rem, inherit, 400);
                opacity: 0.5;
            }
        }

        .title {
            @include font-rubik(1rem, inherit, 700);
            margin: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            word-wrap: break-word;
        }
    }

    .imageWrapper {
        aspect-ratio: 1.2 / 1;
        flex-shrink: 0;
        background-color: var(--dust-120);
        position: relative;

        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .brandMark {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            max-width: 24px;
            max-height: 24px;
            width: auto;
            height: auto;
            object-fit: contain;
        }
    }
}
