@import "../../../../../css/fonts";

.signUpPersonaChild {
    .navigation {
        display: flex;
        justify-content: flex-start;

        .backButton {
            @include font-rubik(14px, var(--gray-darkerer), 600);
        }
    }

    .personas {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 48px;

        :global(.MuiFormControl-root) {
            width: 100%;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 8px;
            margin-top: 16px;
        }

        .input {
            display: flex;
            flex-direction: column;

            .disclaimer {
                @include font-rubik(12px, var(--gray-darker), 400);
                margin-top: 12px;
            }

            .label {
                margin-bottom: 8px;
            }
        }

        .persona {
            background-color: var(--dust-100);
        }

        .selects {
            display: flex;
            justify-content: space-between;
            gap: 16px;
        }

        &--grid {
            display: grid;
            grid-column-gap: 16px;
            grid-template-columns: 1fr 1fr;
        }
    }
}
